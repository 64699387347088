import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
import PubSub from './pubSub'
Swiper.use([Navigation, Pagination, Autoplay])

class Slider {
  constructor(sliderElement) {
    this.slider = sliderElement
  }

  addResizeListeners = () => {
    this.pageWidth = document.documentElement.clientWidth

    PubSub.on('resize', this.watchBreakpoints)
  }

  setCurrentBreakpoint = () => {
    const currentPageWidth = document.documentElement.clientWidth
    const firstBreakpoint = this.udpateBreakpoints[0]
    const lastBreakpoint = this.udpateBreakpoints[this.udpateBreakpoints.length - 1]
    this.udpateBreakpoints.forEach((breakpoint, index) => {
      if (currentPageWidth < firstBreakpoint) {
        this.currentBreakpoint = 'mobile'
      } else if (
        currentPageWidth >= breakpoint &&
        currentPageWidth < this.udpateBreakpoints[index + 1] &&
        breakpoint < lastBreakpoint
      ) {
        this.currentBreakpoint = breakpoint
      } else if (currentPageWidth >= lastBreakpoint) {
        this.currentBreakpoint = lastBreakpoint
      }
    })
  }

  breakpointCrossed = (breakpoint, currentPageWidth) => {
    const previousPageWidth = this.pageWidth
    return (
      (previousPageWidth <= breakpoint && currentPageWidth > breakpoint) ||
      (previousPageWidth > breakpoint && currentPageWidth <= breakpoint)
    )
  }

  initialiseSlider = () => {
    if (!this.slider || !this.sliderConfig || !this.currentBreakpoint) return
    if (!this.sliderConfig['breakpoints_setup'][this.currentBreakpoint]) return
    if (this.slider.className.includes('swiper--hero-mobile') && this.sliderInstance)
      if (this.sliderInstance) this.sliderInstance.destroy()
    this.sliderInstance = new Swiper(this.slider, {
      direction: 'horizontal',
      slidesPerView: this.sliderConfig['breakpoints_setup'][this.currentBreakpoint].slidesPerView,
      spaceBetween: this.sliderConfig['breakpoints_setup'][this.currentBreakpoint].spaceBetween,
      initialSlide: 0,
      width: this.sliderConfig['breakpoints_setup'][this.currentBreakpoint].width,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      watchOverflow: true,
      ...this.sliderConfig,
    })
  }

  handleSliderDestroy = () => {
    if (this.sliderInstance) this.sliderInstance.destroy()
  }

  handleSliderPlay = () => {
    if (!this.sliderInstance) return
    if (this.sliderInstance.el && this.sliderInstance.el.className.includes('slider-1'))
      this.sliderInstance.autoplay.start()
  }

  handleSliderStop = () => {
    if (!this.sliderInstance) return
    if (this.sliderInstance.el && this.sliderInstance.el.className.includes('slider-1'))
      this.sliderInstance.autoplay.stop()
    else return
  }

  initializeControlButtons = () => {
    this.playBtn = document.querySelector('.play')
    this.pauseBtn = document.querySelector('.pause')
    if (!this.playBtn || !this.pauseBtn) return
    this.pauseBtn.addEventListener('click', this.handleSliderStop)
    this.playBtn.addEventListener('click', this.handleSliderPlay)
  }

  init = () => {
    this.addResizeListeners()
    this.parseSliderConfig()
    this.setCurrentBreakpoint()
    this.initializeControlButtons()
    this.initialiseSlider()
  }

  parseSliderConfig = () => {
    this.sliderConfig = JSON.parse(this.slider.dataset?.swiperConfig || '{}')
    this.udpateBreakpoints = JSON.parse(this.slider.dataset?.breakpoints || '[]')
  }

  reinitialiseSlider = () => {
    this.handleSliderDestroy()
    this.parseSliderConfig()
    this.setCurrentBreakpoint()
    this.initializeControlButtons()
    this.initialiseSlider()
  }

  watchBreakpoints = () => {
    const currentPageWidth = document.documentElement.clientWidth
    this.udpateBreakpoints.forEach(breakpoint => {
      if (!this.breakpointCrossed(breakpoint, currentPageWidth)) return
      this.reinitialiseSlider()
      this.pageWidth = currentPageWidth
    })
  }
}

window.addEventListener('load', () => {
  const sliderElements = document.querySelectorAll('.slider')
  if (!sliderElements) return
  sliderElements.forEach((sliderElement, index) => {
    sliderElement.classList.add('slider-' + index)
    const slider = new Slider(sliderElement)
    slider.init()
  })
})

export default Slider
