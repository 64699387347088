class Collapse {
  constructor(button) {
    this.button = button
    this.breakpoints = [768]
    this.isSearch = false
  }

  getContentElement = () => {
    this.content = document.getElementById(this.button.getAttribute('aria-controls'))
  }

  addClickListener = () => {
    this.button.addEventListener('click', this.handleClick)
  }

  addKeyPressListener = () => {
    this.button.addEventListener('keyup', this.debounceFunction(this.handleKeyUp, 2000))
  }

  getFormItems = () => {
    this.searchInputs = document.querySelectorAll('.collapse-content--form input')
    this.searchSelects = document.querySelectorAll('.collapse-content--form select')
  }

  setTabindexes = tabindex => {
    this.searchInputs.forEach(input => input.setAttribute('tabindex', tabindex))
    this.searchSelects.forEach(select => select.setAttribute('tabindex', tabindex))
  }

  handleCollpse = () => {
    if (this.button.getAttribute('aria-expanded') === 'false') {
      this.button.setAttribute('aria-expanded', 'true')
      this.button.classList.add('collapsed')
      this.content.style.maxHeight = `${this.content.scrollHeight}px`
      this.content.classList.add('collapsed')
      this.debounceFunction(400, () => {
        this.content.style.overflow = 'visible'
      })
      this.setTabindexes(0)
    } else {
      this.button.setAttribute('aria-expanded', 'false')
      this.button.classList.remove('collapsed')
      this.content.classList.remove('collapsed')
      this.content.style.maxHeight = null
      this.debounceFunction(0, () => {
        this.content.style.overflow = 'hidden'
      })
      this.setTabindexes(-1)
    }
  }

  setIsSearch = () => {
    this.isSearch = this.button.className.includes('collapse-trigger-search')
  }

  handleKeyUp = event => {
    event.preventDefault()
    if (event.keyCode === 13 || event.keyCode === 32) this.handleCollpse()
  }

  debounceFunction = (delay, func) => {
    clearTimeout(this.timerId)
    this.timerId = setTimeout(func, delay)
  }

  handleClick = event => {
    event.preventDefault()
    this.handleCollpse()
  }

  init = () => {
    this.setIsSearch()
    if (this.isSearch) this.getFormItems()
    this.getContentElement()
    this.addClickListener()
    this.addKeyPressListener()
  }
}

window.addEventListener('DOMContentLoaded', () => {
  const collapseButtons = document.querySelectorAll('.collapse-trigger')
  if (!collapseButtons) return
  collapseButtons.forEach(collapseButton => {
    const collapse = new Collapse(collapseButton)
    collapse.init()
  })
})
