import Vue from 'vue'
import EventBus from '../eventBus'
import axios from 'axios'

Vue.component('vue-search-panel', {
  template: '#vue-search-panel-template',
  props: { regions: Array, categories: Array },
  data() {
    return {
      totalPages: 0,
      totalOffers: 0,
      currentPage: 1,
      offers: [],
      title: '',
      cityName: '',
      category: '',
      workType: '',
      noOffers: false,
      region: '',
      isRemote: '',
      openForForeigners: '',
      isAbroad: '',
      isJobimpulse: '',
      openForDisability: '',
      advancedFilters: [
        'workType',
        'category',
        'region',
        'isRemote',
        'openForForeigners',
        'isAbroad',
        'isJobimpulse',
        'openForDisability',
      ],
      currentFilters: [],
      baseUrl: 'https://api.jobimpulse.pl/jobs',
      queryString: '',
      itemsPerPage: 10,
      params: '',
      isServerError: false,
    }
  },
  methods: {
    handleItemsPerPageChange(object) {
      if (object.itemsPerPage) this.itemsPerPage = object.itemsPerPage
      this.handleFormSubmit()
    },
    getValuesFromParams() {
      const params = new URLSearchParams(window.location.search)
      this.currentPage = params.has('currentPage') ? parseInt(params.get('currentPage')) : 1
      this.itemsPerPage = params.has('itemsPerPage') ? params.get('itemsPerPage') : 10
      this.companyName = params.has('companyName') ? params.get('companyName') : ''
      this.category = params.has('category') ? params.get('category') : ''
      this.region = params.has('region') ? params.get('region') : ''
      this.workType = params.has('workType') ? params.get('workType') : ''
      this.openForDisability =
        params.has('openForDisability') && params.get('openForDisability') == 1 ? true : false
      this.openForForeigners =
        params.has('openForForeigners') && params.get('openForForeigners') == 1 ? true : false
      this.isAbroad = params.has('isAbroad') && params.get('isAbroad') == 1 ? true : false
      this.isRemote = params.has('isRemote') && params.get('isRemote') == 1 ? true : false
      this.isJobimpulse =
        params.has('isJobimpulse') && params.get('isJobimpulse') == 1 ? true : false
      this.cityName = params.has('cityName')
        ? params
            .get('cityName')
            .charAt(0)
            .toUpperCase() + params.get('cityName').slice(1)
        : ''
      this.title = params.has('title') ? params.get('title') : ''
    },
    handleFormSubmit() {
      this.currentPage = 1
      this.totalOffers = 0
      this.offers = []
      this.makeJobsRequest(this.setQueryString())
      this.updateParams()
    },
    updateParams() {
      let url = new URL(window.location)
      url.searchParams.set('currentPage', `${this.currentPage ? this.currentPage : 1}`)
      url.searchParams.set('itemsPerPage', `${this.itemsPerPage ? this.itemsPerPage : 10}`)
      url.searchParams.set('companyName', `${this.companyName ? this.companyName : ''}`)
      url.searchParams.set('category', `${this.category ? this.category : ''}`)
      url.searchParams.set('region', `${this.region ? this.region : ''}`)
      url.searchParams.set('workType', `${this.workType ? this.workType : ''}`)
      url.searchParams.set('openForDisability', `${this.openForDisability ? 1 : ''}`)
      url.searchParams.set('openForForeigners', `${this.openForForeigners ? 1 : ''}`)
      url.searchParams.set('isAbroad', `${this.isAbroad ? 1 : ''}`)
      url.searchParams.set('isRemote', `${this.isRemote ? 1 : ''}`)
      url.searchParams.set('isJobimpulse', `${this.isJobimpulse ? 1 : ''}`)
      url.searchParams.set(
        'cityName',
        `${this.cityName ? this.cityName.charAt(0).toUpperCase() + this.cityName.slice(1) : ''}`
      )
      url.searchParams.set('title', `${this.title ? this.title : ''}`)

      url = url.toString()
      history.pushState({}, '', url)
    },
    setInitialCurrentFilters() {},
    setQueryString() {
      this.queryString = `${this.baseUrl}?
      ${this.currentPage ? '&page=' + this.currentPage : 1}${
        this.itemsPerPage ? '&itemsPerPage=' + this.itemsPerPage : 10
      }${this.companyName ? '&companyName=' + this.companyName : ''}${
        this.category ? '&category=' + this.category : ''
      }${this.region ? '&region=' + this.region : ''}${
        this.workType ? '&workType=' + this.workType : ''
      }${this.openForDisability ? '&openForDisability=1' : ''}${
        this.openForForeigners ? '&openForForeigners=1' : ''
      }${this.isAbroad ? '&isAbroad=1' : ''}${this.isRemote ? '&isRemote=1' : ''}${
        this.isJobimpulse ? '&isJobimpulse=1' : ''
      }${
        this.cityName
          ? '&cityName=' + this.cityName.charAt(0).toUpperCase() + this.cityName.slice(1)
          : ''
      }${this.title ? '&title=' + this.title : ''}`
      return this.queryString
    },
    makeJobsRequest(queryString) {
      axios
        .get(queryString)
        .then(response => {
          if (
            response.status === 200 &&
            response.data &&
            response.data['hydra:member'].length !== 0
          ) {
            this.totalOffers = response.data['hydra:totalItems']
            this.totalPages = Math.ceil(response.data['hydra:totalItems'] / this.itemsPerPage)
            this.offers = response.data['hydra:member']
            EventBus.$emit('jobs-loaded', {
              offers: this.offers,
              totalPages: this.totalPages,
              totalOffers: this.totalOffers,
              currentPage: this.currentPage,
            })
            this.noOffers = false
          } else {
            this.offers = []
            this.noOffers = true
            EventBus.$emit('jobs-loaded', {
              offers: this.offers,
              totalPages: 0,
              totalOffers: 0,
              currentPage: 0,
            })
          }
        })
        .catch(error => {
          this.isServerError = true
        })
    },
    addToCurrentFilters(filterName) {
      if (this.currentFilters.indexOf(filterName) !== -1) return
      this.currentFilters = [...this.currentFilters, filterName]
    },
    removeFromCurrentFilters(filterName) {
      this.currentFilters = this.currentFilters.filter(item => item !== filterName)
    },
    handleActiveCurrenFilters(event) {
      this.advancedFilters.forEach(filter => {
        if (this.$data[filter]) this.addToCurrentFilters(filter)
        else this.removeFromCurrentFilters(filter)
      })
    },
  },
  created() {
    this.getValuesFromParams()
    this.handleActiveCurrenFilters()
    this.makeJobsRequest(this.setQueryString())
    this.updateParams()
    EventBus.$on('items-per-page-change', this.handleItemsPerPageChange)
    EventBus.$on('get-page', object => {
      this.currentPage = object.pageNumber
      this.makeJobsRequest(this.setQueryString())
      this.updateParams()
    })
  },
})

const searchPanel = document.querySelectorAll('#vue-search-panel-wrapper')

window.addEventListener('DOMContentLoaded', () => {
  if (!searchPanel) return
  searchPanel.forEach(element => {
    new Vue({
      el: element,
    })
  })
})
