import Vue from 'vue'

Vue.component('vue-recruiters-item', {
  template: '#vue-recruiters-item-template',
  props: { recruiter: Object },
  data() {
    return {}
  },
  methods: {},

  created() {},
})
