import Vue from 'vue'
import EventBus from '../eventBus'
import axios from 'axios'

Vue.component('vue-job-offer-content', {
  template: '#vue-job-offer-content-template',
  props: { job: Object },
  data() {
    return {}
  },
  methods: {},
  created() {},
})
