import Vue from 'vue'

Vue.component('vue-search-results-list', {
  template: '#vue-search-results-list-template',
  props: { offers: Array },
  data() {
    return {}
  },
  methods: {},
  created() {},
})
