class HeaderForm {
  constructor(formElement) {
    this.form = formElement
    this.input = this.form.querySelector('input')
    this.searchText = ''
    ;(this.baseUrl = 'https://jobimpulse.pl/wyniki-wyszukiwania/'), (this.redirectUrl = '')
  }

  addClickListener = () => {
    this.form.addEventListener('submit', this.handleFormSubmit)
  }
  handleFormSubmit = e => {
    e.preventDefault()
    this.setSearchText()
    this.setSearchUrl()
    this.redirectToSearchPage()
  }

  setSearchText = () => {
    switch (this.form.id) {
      case 'search-form-hamburger':
        this.searchText = this.form['title2'].value
        break
      case 'search-form-desktop':
        this.searchText = this.form['title1'].value
        break
    }
  }

  setSearchUrl = () => {
    this.searchUrl = this.searchText ? `${this.baseUrl}?title=${this.searchText}` : this.baseUrl
  }

  redirectToSearchPage = () => {
    window.location.href = this.searchUrl
  }

  init = () => {
    this.addClickListener()
  }
}

window.addEventListener('DOMContentLoaded', () => {
  const formElements = document.querySelectorAll('.form-header')
  if (!formElements) return
  formElements.forEach(formElement => {
    const form = new HeaderForm(formElement)
    form.init()
  })
})
