function Menu(header, hamburger, secondMenu) {
  this.header = header
  this.accordionToggleButtons = document.querySelectorAll(
    '.second-nav .menu-item-has-children a[href="#"]'
  )
  this.secondMenu = secondMenu
  this.hamburger = hamburger
  this.submenuTriggers = document.querySelectorAll(
    '.menu-main-menu-container ul.menu > li.menu-item-has-children  > a'
  )
  this.mainSubmenuToggleButtons = document.querySelectorAll(
    '.menu-main-menu-container .menu-item-has-children'
  )
  this.submenuElements = document.querySelectorAll(
    '.menu-main-menu-container ul.menu > li.menu-item-has-children ul.sub-menu'
  )

  this.accordionSubmenusLinks = document.querySelectorAll(
    '.menu-top-hamburger-menu-container li.menu-item-has-children ul.sub-menu a'
  )

  this.handleHamburgerMenuClick = () => {
    this.hamburger.classList.toggle('open')
    this.secondMenu.classList.toggle('open')
    if (!this.hamburger.className.includes('open')) {
      this.resetAriaExpanded()
      this.accordionToggleButtons.forEach(item => {
        item.classList.remove('active')
      })
    }
  }

  this.toggleAriaExpanded = element => {
    if (element.className.includes('active')) {
      element.setAttribute('aria-expanded', 'true')
    } else {
      element.setAttribute('aria-expanded', 'false')
    }
  }

  this.toggleAccordionsItem = item => {
    item.addEventListener('click', event => {
      if (!event) return
      event.preventDefault()
      event.stopPropagation()
      event.target.parentNode.classList.toggle('active')
      const panel = event.target.nextElementSibling
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null
      } else {
        panel.style.maxHeight = `${event.target.nextElementSibling.scrollHeight}px`
      }
      this.toggleAriaExpanded(event.target.parentNode)
    })
  }

  this.initHamburgerMenu = () => {
    hamburger.addEventListener('click', () => {
      this.handleHamburgerMenuClick()
    })
  }

  this.handleSubmenuTrigger = e => {
    e.preventDefault()
    switch (e.key) {
      case 'Enter':
        e.target.nextElementSibling.classList.add('open')
        e.target.nextElementSibling.querySelector('a').focus()
        break
      case 'Escape':
        e.target.nextElementSibling.classList.remove('open')
        break
    }
  }

  this.initSubmenuTriggers = () => {
    this.submenuTriggers.forEach(el => {
      el.addEventListener('keyup', this.handleSubmenuTrigger)
    })
    this.submenuTriggers.forEach(el => {
      el.addEventListener('click', e => {
        e.preventDefault()
      })
    })
  }

  this.handleSubmenuClose = e => {
    switch (e.key) {
      case 'Escape':
        e.target.parentNode.parentNode.classList.remove('open')
        e.target.parentNode.parentNode.previousSibling.previousSibling.focus()
    }
  }

  this.addSubmenuElementsListeners = () => {
    this.submenuElements.forEach(el => {
      el.addEventListener('keyup', this.handleSubmenuClose)
    })
  }

  this.initAccordions = () => {
    this.accordionToggleButtons.forEach(this.toggleAccordionsItem)
  }
  this.init = () => {
    this.addSubmenuElementsListeners()
    this.initSubmenuTriggers()
    this.initAccordions(this.accordionToggleButtons)
    this.initHamburgerMenu()
  }

  this.resetAriaExpanded = () => {
    this.accordionToggleButtons.forEach(element => {
      element.ariaExpanded = 'false'
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const header = document.getElementById('header')
  const hamburger = document.getElementById('toggle')
  const secondMenu = document.getElementById('second-nav')
  if (!header || !hamburger || !secondMenu) return
  const menu = new Menu(header, hamburger, secondMenu)
  menu.init()
})
