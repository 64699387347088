import Vue from 'vue'

Vue.component('vue-job-offer-box', {
  template: '#vue-job-offer-box-template',
  props: { job: Object },
  data() {
    return {
      addOfferTime: '',
    }
  },
  methods: {
    setaddTimeString(data) {
      const today = Date.now()
      const datum = Date.parse(data)
      const passedTime = (today - datum) / (60 * 60 * 24 * 1000)
      if (passedTime < 1) {
        this.addOfferTime = 'Dzisiaj'
      } else if (passedTime < 2) {
        this.addOfferTime = '1 dzień temu'
      } else {
        this.addOfferTime = `${passedTime.toFixed()} dni temu`
      }
    },
    init() {
      this.setaddTimeString(this.job.createdAt)
    },
  },
  created() {
    this.init()
  },
  updated() {
    this.$nextTick(this.setaddTimeString(this.job.createdAt))
  },
})
