import Vue from 'vue'

Vue.component('vue-province', {
  template: '#vue-province-template',
  props: { province: Object, recruitersData: Array },
  data() {
    return {}
  },
  methods: {},

  created() {},
})
