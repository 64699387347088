import PubSub from './pubSub'

class Hero {
  constructor(element) {
    this.hero = element
    this.pageWidth = null
    this.breakpoints = [768]
    this.currentPageWidth = null
  }

  addResizeListeners = () => {
    this.pageWidth = document.documentElement.clientWidth

    PubSub.on('resize', this.watchBreakpoints)
  }

  breakpointCrossed = (breakpoint, currentPageWidth) => {
    const previousPageWidth = this.pageWidth
    return (
      (previousPageWidth < breakpoint && currentPageWidth >= breakpoint) ||
      (previousPageWidth >= breakpoint && currentPageWidth < breakpoint)
    )
  }

  init = () => {
    this.addResizeListeners()
    this.setCurrentPageWIdth()
    this.setIsMobile()
    this.handleMobileClass()
  }

  setCurrentPageWIdth = () => {
    this.currentPageWidth = document.documentElement.clientWidth
  }

  setIsMobile = () => {
    if (this.currentPageWidth < 768) this.isMobile = true
    else this.isMobile = false
  }

  handleMobileClass = () => {
    if (this.isMobile) this.hero.classList.add('hero-employer--mobile')
    else this.hero.classList.remove('hero-employer--mobile')
  }

  watchBreakpoints = () => {
    this.setCurrentPageWIdth()
    this.breakpoints.forEach(breakpoint => {
      if (!this.breakpointCrossed(breakpoint, this.currentPageWidth)) return
      this.setIsMobile()
      this.handleMobileClass()
      this.pageWidth = this.currentPageWidth
    })
  }
}

window.addEventListener('DOMContentLoaded', () => {
  const heroElement = document.querySelector('.hero-editable-background')
  if (!heroElement) return

  const hero = new Hero(heroElement)
  hero.init()
})
