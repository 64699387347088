import Vue from 'vue'
import EventBus from '../eventBus'
import axios from 'axios'

Vue.component('vue-blog-content', {
  template: '#vue-blog-content-template',
  props: {},
  data() {
    return {
      title: '',
      category: '',
    }
  },
  methods: {},
  created() {},
})

const blogContent = document.querySelectorAll('#vue-blog-content-wrapper')

window.addEventListener('DOMContentLoaded', () => {
  if (!blogContent) return
  blogContent.forEach(element => {
    new Vue({
      el: element,
    })
  })
})
