import Vue from 'vue'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import EventBus from '../eventBus'
gsap.registerPlugin(ScrollToPlugin)
Vue.component('vue-pagination', {
  template: '#vue-pagination-template',
  props: {
    currentPage: Number,
    isDisabledNext: Boolean,
    isDisabledPrev: Boolean,
    isMobile: Boolean,
    isdisabled: Boolean,
    totalPages: Number,
    posts: Number,
  },
  data() {
    return {
      nextPageId: null,
      prevPageId: null,
      totalPosts: this.posts,
      pagination: null,
      currentPageNumber: this.currentPage,
    }
  },
  methods: {
    setNextPageId() {
      this.nextPageId = this.currentPageNumber + 1
      return parseInt(this.nextPageId)
    },
    setPrevPageId() {
      this.prevPageId = this.currentPageNUmber - 1
      return parseInt(this.prevPageId)
    },
    handleNextClick() {
      if (this.isDisabledNext) return
      EventBus.$emit('get-page', { pageNumber: this.setNextPageId() })
    },
    handlePrevClick() {
      if (this.isDisabledPrev) return
      EventBus.$emit('get-page', { pageNumber: this.setPrevPageId() })
    },
    handlePageButtonClick(event) {
      if (parseInt(event.target.dataset.value) !== this.currentPage) {
        EventBus.$emit('get-page', { pageNumber: parseInt(event.target.dataset.value) })
      }
    },
    scrollToBegin(object) {
      this.currentPageNumber = object.currentPage
      gsap.to(window, { duration: 1, scrollTo: 'scroll-to' })
    },
    handlePaginationVisibility() {
      if (!this.totalPosts || this.totalPages == 1) this.pagination.classList.add('disabled')
      else this.pagination.classList.remove('disabled')
    },
  },
  created() {
    EventBus.$on('jobs-loaded', this.scrollToBegin)
    EventBus.$on('posts', object => {
      this.totalPages = parseInt(object.totalPages)
      this.currentPage = parseInt(object.currentPage)
      this.totalPosts = parseInt(object.totalPosts)
      this.isDisabledNext = object.isDisabledNext
      this.isDisabledPrev = object.isDisabledPrev
      this.handlePaginationVisibility()
      this.scrollToBegin(object)
    })
  },
  mounted() {
    this.pagination = document.querySelector('.pagination')
    this.handlePaginationVisibility()
  },
})

const paginationWrapper = document.querySelector('#vue-pagination-wrapper')

window.addEventListener('DOMContentLoaded', () => {
  if (!paginationWrapper) return
  new Vue({
    el: paginationWrapper,
  })
})
