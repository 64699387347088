import Vue from 'vue'

Vue.component('vue-icons-block', {
  template: '#vue-icons-block-template',
  props: {
    openForDisability: Boolean,
    openForForeigners: Boolean,
    isRemote: Boolean,
    isAbroad: Boolean,
    isJobimpulse: Boolean,
  },
  data() {
    return {}
  },
})
