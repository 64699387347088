import Vue from 'vue'
import Scroll from './scroll'

Vue.component('vue-job-offer-page', {
  template: '#vue-job-offer-page-template',
  props: { job: Object },
  data() {
    return {
      query: '',
    }
  },
  methods: {
    generateQuery() {
      this.query = `https://api.jobimpulse.pl/jobs?page=1&itemsPerPage=5&region=${this.job.region}`
    },
    runScroll() {
      this.element = document.querySelector('.hero-job-offer')
      this.scrollInstance = new Scroll(this.element)
      this.scrollInstance.init()
    },
  },
  created() {
    this.generateQuery()
  },
  mounted() {
    this.$nextTick(this.runScroll)
  },
})

const jobOfferPageEl = document.querySelectorAll('#vue-job-offer-page-wrapper')
window.addEventListener('DOMContentLoaded', () => {
  if (!jobOfferPageEl) return
  jobOfferPageEl.forEach(element => {
    new Vue({
      el: element,
    })
  })
})
