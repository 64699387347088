import Vue from 'vue'
import axios from 'axios'
import Slider from './slider'

Vue.component('vue-job-offers-block', {
  template: '#vue-job-offers-block-template',
  props: {
    query: String,
    turnOff: Boolean,
    choosenJob: Object,
    clientName: String,
    region: Number,
  },
  data() {
    return {
      jobs: [],
      loading: true,
      noOffers: false,
      isServerError: false,
      baseUrl: 'https://api.jobimpulse.pl/jobs',
      queryString: '',
      page: 1,
      itemsPerPage: 5,
      isSingleEmployer: document.querySelector('body.single-employer') ? true : false,
      isSingleJob: document.querySelector('body.oferta-pracy') ? true : false,
      isWorkInJob: document.querySelector('body.page-template-work-in-job-template') ? true : false,
    }
  },
  methods: {
    runSliderElement() {
      this.sliderElement = document.querySelector('.swiper--job-offers')
      this.sliderInstance = new Slider(this.sliderElement)
      this.sliderInstance.init()
    },
    encodeQueryData(data) {
      const ret = []
      const keys = Object.keys(data)

      keys.forEach(key => {
        if (data[key] !== undefined) {
          ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        }
      })

      return ret.join('&')
    },
    setQuery() {
      const queryObject = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        companyName: this.companyName,
        category: this.category,
        region: this.region,
        workType: this.workType,
        // openForDisability: this.openForDisability ? '1' : '0',
        // openForForeigners: this.openForForeigners ? '1' : '0',
        // isAbroad: this.isAbroad ? '1' : '0',
        // isRemote: this.isRemote ? '1' : '0',
        // isJobimpulse: this.isJobimpulse ? '1' : '0',
        cityName: this.cityName
          ? this.cityName.charAt(0).toUpperCase() + this.cityName.slice(1)
          : undefined,
        title: this.title,
        client: this.clientName,
      }

      this.queryString = `${this.baseUrl}?`
      this.queryString = `${this.queryString}${this.encodeQueryData(queryObject)}`

      return this.queryString
    },
    getJobs(query) {
      this.loading = true
      axios
        .get(query)
        .then(response => {
          if (response.status === 200 && response.data && response.data['hydra:member'].length) {
            this.jobs = [...this.jobs, ...response.data['hydra:member']]
            if (this.isSingleJob && this.choosenJob) {
              this.jobs = this.jobs.filter(el => el.id !== this.choosenJob.id)
            }
            this.totalPages = Math.ceil(response.data['hydra:totalItems'] / this.itemsPerPage)
            this.loading = false
            this.noOffers = false
            this.isServerError = false
          } else {
            /* load job with no filters then get jobs result is empty */
            if (this.isWorkInJob) {
              this.loading = false
              this.isServerError = false
              this.noOffers = true

              return
            }

            if (this.page === 1 && !response.data['hydra:member'].length && !this.isSingleEmployer)
              this.getJobs('https://api.jobimpulse.pl/jobs?page=1&itemsPerPage=5')
            this.loading = false
            this.isServerError = false
            this.moreButton.style.display = 'none'
            this.noOffers = true
          }
        })
        .catch(function(error) {
          console.log('ERROR', error)
          if (!error.response) return
        })
    },
    initMoreButton() {
      this.moreButton = document.querySelector('.btn-more-wrapper')
      if (!this.moreButton) return
      this.moreButton.addEventListener('click', () => {
        this.page = this.page + 1
        this.getJobs(this.setQuery())
      })
    },
    init() {
      console.log('this.isWorkInJob', this.isWorkInJob)
      this.setQuery()
    },
  },
  created() {
    this.init()
  },
  mounted() {
    this.getJobs(this.query)
    this.initMoreButton()
  },
  updated() {
    if (!this.isSingleEmployer && !this.turnOff) this.$nextTick(this.runSliderElement)
  },
})

const jobOffersSlider = document.querySelectorAll('#vue-job-offers-slider-wrapper')

window.addEventListener('DOMContentLoaded', () => {
  if (!jobOffersSlider) return
  jobOffersSlider.forEach(element => {
    new Vue({
      el: element,
    })
  })
})
