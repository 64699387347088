class BidRequest {
  constructor() {
    this.step1div = document.querySelector('.form-step-1')
    this.step2div = document.querySelector('.form-step-2')
    this.step3div = document.querySelector('.form-step-3')
    // this.step4div = document.querySelector('.step-4')
    this.logoContainer = document.querySelector('.logo-container')
    this.formElement = document.querySelector('.wpcf7-form')
  }

  init() {
    document.getElementById('step1-button').addEventListener('click', () => {
      this.loadPageTwo()
    })
    document.getElementById('step2-button').addEventListener('click', () => {
      this.loadPageThree()
    })
    document.getElementById('step2-back').addEventListener('click', () => {
      this.init()
    })
    document.getElementById('step3-back').addEventListener('click', () => {
      this.loadPageTwo()
    })

    this.formElement.addEventListener('wpcf7mailsent', () => {
      window.location = 'https://jobimpulse.pl/thank-you/'
    })
    this.step1div.style.display = 'block'
    this.step2div.style.display = 'none'
    this.step3div.style.display = 'none'
  }

  loadPageTwo() {
    this.step1div.style.display = 'none'
    this.step2div.style.display = 'block'
    this.step3div.style.display = 'none'
  }

  loadPageThree() {
    this.step1div.style.display = 'none'
    this.step2div.style.display = 'none'
    this.step3div.style.display = 'block'
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const bidRequestElement = document.querySelector('.bid-request')
  if (!bidRequestElement) return
  const bidRequest = new BidRequest(bidRequestElement)
  bidRequest.init()
})
