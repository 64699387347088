import Vue from 'vue'

Vue.component('vue-hero-form', {
  template: '#vue-hero-form-template',
  data() {
    return {
      title: '',
      cityName: '',
      baseUrl: 'https://jobimpulse.pl/oferty-pracy/',
      // baseUrl: 'https://localhost/jobimpulse/oferty-pracy/',
      url: '',
    }
  },
  methods: {
    handleFormSubmit(e) {
      e.preventDefault()
      let url = new URL(this.baseUrl)
      url.searchParams.set('currentPage', `${this.currentPage ? this.currentPage : 1}`)
      url.searchParams.set('itemsPerPage', `${this.itemsPerPage ? this.itemsPerPage : 10}`)
      url.searchParams.set('companyName', `${this.companyName ? this.companyName : ''}`)
      url.searchParams.set('category', `${this.category ? this.category : ''}`)
      url.searchParams.set('region', `${this.region ? this.region : ''}`)
      url.searchParams.set('workType', `${this.workType ? this.workType : ''}`)
      url.searchParams.set('openForDisability', `${this.openForDisability ? 1 : ''}`)
      url.searchParams.set('openForForeigners', `${this.openForForeigners ? 1 : ''}`)
      url.searchParams.set('isAbroad', `${this.isAbroad ? 1 : ''}`)
      url.searchParams.set('isRemote', `${this.isRemote ? 1 : ''}`)
      url.searchParams.set('isJobimpulse', `${this.isJobimpulse ? 1 : ''}`)
      url.searchParams.set(
        'cityName',
        `${this.cityName ? this.cityName.charAt(0).toUpperCase() + this.cityName.slice(1) : ''}`
      )
      url.searchParams.set('title', `${this.title ? this.title : ''}`)
      location.href = url.toString()
    },
    init() {},
  },

  created() {},
})

const heroFormEl = document.querySelectorAll('#vue-hero-form-wrapper')
window.addEventListener('DOMContentLoaded', () => {
  if (!heroFormEl) return
  heroFormEl.forEach(element => {
    new Vue({
      el: element,
    })
  })
})
