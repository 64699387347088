import throttle from 'lodash.throttle'
function Scroll(element) {
  this.element = element
  this.startElementHeight = null
  this.addOnsScrollListener = () => {
    window.addEventListener('scroll', throttle(this.toggleScrollClasses, 400))
    document.body.scroll(0, 0)
  }
  this.toggleScrollClasses = () => {
    if (
      (document.body.scrollTop > 0 && document.body.scrollTop > 70) ||
      document.documentElement.scrollTop > 70
    ) {
      document.body.classList.add('is-scrolled')
      if (
        document.body.className.includes('oferta-pracy') &&
        this.element.className.includes('hero-job-offer')
      ) {
        document.querySelector('.main').style.paddingTop = `${this.element.offsetHeight +
          this.headerHeight}px`
      }
      this.element.classList.add('is-scrolled')
    } else {
      this.element.classList.remove('is-scrolled')
      if (this.element.className.includes('hero-job-offer')) {
        document.body.classList.remove('is-scrolled')
        document.querySelector('.main').style.paddingTop = `${this.startElementHeight +
          this.headerHeight}px`
      }
    }
  }
  this.init = () => {
    if (this.element.className.includes('hero-job-offer')) {
      this.headerHeight = document.querySelector('#header').offsetHeight
      this.startElementHeight = this.element.offsetHeight
      document.querySelector('.main').style.paddingTop = `${this.element.offsetHeight +
        this.headerHeight}px`
    }
    // if (document.body.scrollTop > 603 || document.documentElement.scrollTop > 603) {
    //   this.toggleScrollClasses()
    // }
    this.addOnsScrollListener()
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.scroll-fixed')
  if (!elements) return
  elements.forEach(element => {
    const scroll = new Scroll(element)
    scroll.init()
  })
})

export default Scroll
