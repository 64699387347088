import throttle from 'lodash.throttle'
import mitt from 'mitt'

const THROTTLE_THRESHOLD = 5
const RESIZE_EVENTS = ['orientationchange', 'resize']
const PubSub = mitt()

RESIZE_EVENTS.forEach(eventType => {
  window.addEventListener(
    eventType,
    throttle(() => {
      PubSub.emit('resize')
    }, THROTTLE_THRESHOLD)
  )
})

// window.addEventListener(
//   'scroll',
//   throttle(() => {
//     PubSub.emit('scroll')
//   }, THROTTLE_THRESHOLD)
// )

export default PubSub
