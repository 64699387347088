import Vue from 'vue'

Vue.component('vue-recruiters-tabs', {
  template: '#vue-recruiters-tabs-template',
  data() {
    return {
      recruitersData: [
        {
          id: 0,
          name: 'Illia Horobets',
          phone: '48 509 432 335',
          email: 'illia.horobets@jobimpulse.pl',
          image: 'illia-horobets.webp',
          imageMobile: 'illia-horobets-mobile.webp',
        },
        {
          id: 1,
          name: 'Kamila Piechowska',
          phone: '48 504 791 396',
          email: 'kamila.piechowska@jobimpulse.pl',
          image: 'no-image.webp',
          imageMobile: 'no-image-mobile.webp',
        },
        {
          id: 2,
          name: 'Olena Kmita',
          phone: '48 502 526 851',
          email: 'olena.kmita@jobimpulse.pl',
          image: 'no-image.webp',
          imageMobile: 'no-image-mobile.webp',
        },
        {
          id: 3,
          name: 'Monika Glińska',
          phone: '48 501 587 563',
          email: 'monika.glinska@jobimpulse.pl',
          image: 'monika-glinska.webp',
          imageMobile: 'monika-glinska-mobile.webp',
        },
        {
          id: 4,
          name: 'Kristina Kovleva',
          phone: '48 502 298 368',
          email: 'kristina.kovleva@jobimpulse.pl',
          image: 'no-image.webp',
          imageMobile: 'no-image-mobile.webp',
        },
        {
          id: 5,
          name: 'Sandra Zasiadczyk',
          phone: '48 501 081 565',
          email: 'sandra.zasiadczyk@jobimpulse.pl',
          image: 'sandra-zasiadczyk.webp',
          imageMobile: 'sandra-zasiadczyk-mobile.webp',
        },
        {
          id: 6,
          name: 'Kamila Krajewska',
          phone: '48 797 387 411',
          email: 'kamila.krajewska@jobimpulse.pl',
          image: 'kamila-krajewska.webp',
          imageMobile: 'kamila-krajewska-mobile.webp',
        },
        {
          id: 7,
          name: 'Yelyzaveta Serzhanova',
          phone: '48 789 220 478',
          email: 'yelyzaveta.serzhanova@jobimpulse.pl',
          image: 'yelyzaveta-serzhanova.webp',
          imageMobile: 'yelyzaveta-serzhanova-mobile.webp',
        },
        {
          id: 8,
          name: 'Sofiia Herasymiuk',
          phone: '48 505 132 371',
          email: 'soffia.herasymiuk@jobimpulse.pl',
          image: 'no-image.webp',
          imageMobile: 'no-image-mobile.webp',
        },
        {
          id: 9,
          name: 'Olha Kolosova',
          phone: '48 512 664 701',
          email: 'olha.kolosova@jobimpulse.pl',
          image: 'no-image.webp',
          imageMobile: 'no-image-mobile.webp',
        },
        {
          id: 10,
          name: 'Mariia Nepomiashcha',
          phone: '48 513 872 346',
          email: 'mariia.nepomiashcha@jobimpulse.pl',
          image: 'no-image.webp',
          imageMobile: 'no-image-mobile.webp',
        },
        {
          id: 11,
          name: 'Agnieszka Milbauer',
          phone: '48 505 426 846',
          email: 'agnieszka.milbauer@jobimpulse.pl',
          image: 'no-image.webp',
          imageMobile: 'no-image-mobile.webp',
        },
        {
          id: 12,
          name: 'Emilia Brożek',
          phone: '48 798 476 040',
          email: 'emilia.brozek@jobimpulse.pl',
          image: 'no-image.webp',
          imageMobile: 'no-image-mobile.webp',
        },
        {
          id: 13,
          name: 'Alona Panko',
          phone: '48 517 811 248',
          email: 'alona.panko@jobimpulse.pl',
          image: 'alona-panko.webp',
          imageMobile: 'alona-panko-mobile.webp',
        },
        {
          id: 14,
          name: 'Joanna Moroń',
          phone: '48 512 097 920',
          email: 'joanna.moron@jobimpulse.pl',
          image: 'no-image.webp',
          imageMobile: 'no-image-mobile.webp',
        },
        {
          id: 15,
          name: 'Karolina Majewska',
          phone: '48 517 945 597',
          email: 'karolina.majewska@jobimpulse.pl',
          image: 'karolina-majewska.webp',
          imageMobile: 'karolina-majewska-mobile.webp',
        },
        {
          id: 16,
          name: 'Aleksandra Rybińska',
          phone: '48 512 100 493',
          email: 'aleksandra.rybinska@jobimpulse.pl',
          image: 'no-image.webp',
          imageMobile: 'no-image-mobile.webp',
        },
        {
          id: 17,
          name: 'Alina Rudenko',
          phone: '48 512 505 025',
          email: 'alina.rudenko@jobimpulse.pl',
          image: 'no-image.webp',
          imageMobile: 'no-image-mobile.webp',
        },
        {
          id: 18,
          name: 'Izabela Kaźmierczak',
          phone: '48 504 374 834',
          email: 'izabela.kazmierczak@jobimpulse.pl',
          image: 'no-image.webp',
          imageMobile: 'no-image-mobile.webp',
        },
      ],
      provinces: [
        {
          name: 'dolnośląskie',
          id: 0,
          recruitersIds: [0, 1, 2],
          isActive: false,
        },
        {
          name: 'kujawsko-pomorskie',
          id: 1,
          recruitersIds: [3, 2],
          isActive: false,
        },
        {
          name: 'lubelskie',
          id: 2,
          recruitersIds: [4],
          isActive: false,
        },
        {
          name: 'lubuskie',
          id: 3,
          recruitersIds: [2],
          isActive: false,
        },
        {
          name: 'łódzkie',
          id: 4,
          recruitersIds: [5, 6, 7, 4, 9, 10, 17, 18],
          isActive: false,
        },
        {
          name: 'małopolskie',
          id: 5,
          recruitersIds: [4, 11, 12],
          isActive: false,
        },
        {
          name: 'mazowieckie',
          id: 6,
          recruitersIds: [13, 14, 4],
          isActive: false,
        },
        {
          name: 'opolskie',
          id: 7,
          recruitersIds: [4],
          isActive: false,
        },
        {
          name: 'podkarpackie',
          id: 8,
          recruitersIds: [2, 4, 11, 12],
          isActive: false,
        },
        {
          name: 'podlaskie',
          id: 9,
          recruitersIds: [2, 4],
          isActive: false,
        },
        {
          name: 'pomorskie',
          id: 10,
          recruitersIds: [2],
          isActive: false,
        },
        {
          name: 'śląskie',
          id: 11,
          recruitersIds: [11, 4, 12],
          isActive: false,
        },
        {
          name: 'świętokrzyskie',
          id: 12,
          recruitersIds: [2],
          isActive: false,
        },
        {
          name: 'warmińsko-mazurskie',
          id: 14,
          recruitersIds: [2],
          isActive: false,
        },
        {
          name: 'wielkopolskie',
          id: 15,
          recruitersIds: [15, 2, 16],
          isActive: false,
        },
        {
          name: 'zachodnioporskie',
          id: 16,
          recruitersIds: [15, 2],
          isActive: false,
        },
      ],
      selectedProvince: '',
      isAnyTabVisible: false,
    }
  },
  methods: {
    handleChange() {
      this.setAllTabsInactive()
      this.setActiveTab()
      this.isAnyTabVisible = true
    },
    setAllTabsInactive() {
      this.provinces.forEach(element => (element.isActive = false))
    },
    setActiveTab() {
      this.provinces.find(province => province.id === this.selectedProvince).isActive = true
      this.provinces.find(province => province.id === this.selectedProvince).tabindex = 1
    },
  },

  created() {},
})

const recruitersTab = document.querySelector('#vue-recruiters-tabs-wrapper')

window.addEventListener('DOMContentLoaded', () => {
  if (!recruitersTab) return
  new Vue({
    el: recruitersTab,
  })
})
