class Upload {
  constructor(upload) {
    this.input = upload
    this.fileName = ''
    this.textNode = ''
  }

  createTextNode = () => {
    this.textNode = document.createElement('span')
    this.textNode.classList.add('file-name')
  }

  init = () => {
    this.fileInfo = this.input.parentElement
    this.input.addEventListener('change', event => {
      this.fileName = event.target.value.split('\\').pop()
      if (this.input.parentNode.className.includes('file-uploaded') && this.textNode) {
        this.textNode.innerHTML = `${this.fileName}`
      } else {
        this.createTextNode()
        this.textNode.innerHTML = `${this.fileName}`
        this.fileInfo.appendChild(this.textNode)
        this.input.parentNode.classList.add('file-uploaded')
      }
    })
    this.input.addEventListener('focus', () => {
      this.input.parentElement.classList.add('focused')
    })
    this.input.addEventListener('blur', () => {
      this.input.parentElement.classList.remove('focused')
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const uploadElement = document.querySelector('input[type=file]')
  if (!uploadElement) return
  const upload = new Upload(uploadElement)
  upload.init()
})
