class CustomCheckbox {
  constructor(customCheckboxEl) {
    this.input = customCheckboxEl
  }

  addChangeListener = () => {
    this.input.addEventListener('change', this.handleChange)
  }

  addFocusListener = () => {
    this.input.addEventListener('focus', this.handleFocus)
  }

  addBlurListener = () => {
    this.input.addEventListener('blur', this.handleBlur)
  }

  handleChange = () => {
    if (!this.input.checked) {
      this.input.parentElement.classList.add('checked')
    } else this.input.parentElement.classList.remove('checked')
  }

  handleFocus = () => {
    this.input.parentElement.classList.add('focused')
  }

  handleBlur = () => {
    this.input.parentElement.classList.remove('focused')
  }

  init = () => {
    if (this.input.checked) this.input.parentElement.classList.add('checked')
    this.addChangeListener()
    this.addBlurListener()
    this.addFocusListener()
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const customCheckboxEl = document.querySelector('.wpcf7-acceptance input')
  if (!customCheckboxEl) return
  const checkbox = new CustomCheckbox(customCheckboxEl)
  checkbox.init()
})
