class Accordion {
  constructor(accordion) {
    this.accordion = accordion
    this.triggers = document.querySelectorAll('.accordion-trigger')
    this.panels = document.querySelectorAll('.accordion-panel')
  }

  addClickListener = () => {
    this.triggers.forEach(trigger => {
      trigger.addEventListener('click', this.handleClick)
    })
  }

  addKeyboardListener = () => {
    this.triggers.forEach(trigger => {
      trigger.addEventListener('keyup', this.handleKeyboard)
    })
  }

  handleKeyboard = event => {
    event.preventDefault()
    const target = event.target
    const isExpanded = target.getAttribute('aria-expanded') === 'true'
    const active = this.accordion.querySelector('[aria-expanded="true"]')
    if (event.keyCode === 13 || event.keyCode === 32) {
      if (active && active !== target) {
        this.closeAccordionItem(active)
      }

      if (!isExpanded) {
        window.setTimeout(this.openAccordionItem, 400, target)
      }
    }
  }

  openAccordionItem = target => {
    target.setAttribute('aria-expanded', 'true')
    const panel = document.getElementById(target.getAttribute('aria-controls'))
    const item = target.parentElement.parentElement
    document.getElementById(target.getAttribute('aria-controls')).removeAttribute('hidden')
    window.setTimeout(() => {
      panel.style.maxHeight = `${panel.scrollHeight}px`
      panel.style.opacity = '1'
      item.style.boxShadow = '1px 3px 8px rgba(0, 0, 0, 0.1), inset 4px 0px 0px #D20A11'
    }, 400)
  }

  closeAccordionItem = active => {
    const activePanel = document.getElementById(active.getAttribute('aria-controls'))
    const item = active.parentElement.parentElement
    active.setAttribute('aria-expanded', 'false')
    activePanel.style.maxHeight = 0
    activePanel.style.opacity = 0

    window.setTimeout(() => {
      activePanel.setAttribute('hidden', '')
      item.style.boxShadow = '1px 3px 8px rgba(0, 0, 0, 0.1)'
    }, 400)
  }

  handleClick = event => {
    const target = event.target
    const isExpanded = target.getAttribute('aria-expanded') === 'true'
    const active = this.accordion.querySelector('[aria-expanded="true"]')

    if (active && active !== target) {
      this.closeAccordionItem(active)
    }

    if (!isExpanded) {
      window.setTimeout(this.openAccordionItem, 400, target)
    }
  }

  init = () => {
    this.addClickListener()
    this.addKeyboardListener()
  }
}

window.addEventListener('DOMContentLoaded', () => {
  const accordionElements = document.querySelectorAll('.accordion')
  if (!accordionElements) return
  accordionElements.forEach(accordionElement => {
    const accordion = new Accordion(accordionElement)
    accordion.init()
  })
})
