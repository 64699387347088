import Vue from 'vue'
import PubSub from './pubSub'
import EventBus from '../eventBus'

Vue.component('vue-search-results', {
  template: '#vue-search-results-template',
  data() {
    return {
      offersPerPage: 10,
      pageData: {},
      pageNumber: null,
      totalPages: null,
      totalOffers: null,
      offers: [],
      pageId: 0,
      currentPage: 1,
      isDisabledPrev: true,
      isDisabledNext: true,
      isMobile: false,
      pageWidth: null,
      breakpoints: [768],
      itemsPerPage: 10,
    }
  },
  methods: {
    setIsMobile(breakpoint, currentPageWidth) {
      if (currentPageWidth < breakpoint) this.isMobile = true
      else this.isMobile = false
    },
    setIsDisabledNext() {
      this.isDisabledNext = this.currentPage === this.totalPages ? true : false
    },
    setIsDisabledPrev() {
      this.isDisabledPrev = this.currentPage === 1 ? true : false
    },
    handleChange() {
      EventBus.$emit('items-per-page-change', { itemsPerPage: this.itemsPerPage })
    },
  },
  created() {
    EventBus.$on('jobs-loaded', object => {
      this.offers = object.offers
      this.currentPage = object.currentPage
      this.totalPages = object.totalPages
      this.totalOffers = object.totalOffers
      this.setIsDisabledNext()
      this.setIsDisabledPrev()
    })
  },
})

const searchResults = document.querySelectorAll('#vue-search-results-wrapper')

window.addEventListener('DOMContentLoaded', () => {
  if (!searchResults) return
  searchResults.forEach(element => {
    new Vue({
      el: element,
    })
  })
})
