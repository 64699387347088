import Slider from './slider'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

class Tabs {
  constructor() {
    this.tabList = document.querySelectorAll('[role="tablist"]')[0]
    this.tabs = document.querySelectorAll('[role="tab"]')
    this.panels = document.querySelectorAll('[role="tabpanel"]')
  }

  init = () => {
    this.addClickListeners()
    this.addScrollToListener()
    // this.tabs[0].focus()
  }

  addClickListeners = () => {
    this.tabs.forEach(tab => {
      tab.addEventListener('click', this.activateTab)
    })
  }

  runSliderElement = () => {
    this.sliderElement = document.querySelector('.swiper--work-in-job')
    this.sliderInstance = new Slider(this.sliderElement)
    this.sliderInstance.init()
  }

  activateTab = e => {
    this.deactivateTabs()
    e.target.removeAttribute('tabindex')
    e.target.setAttribute('aria-selected', 'true')
    document.getElementById(e.target.getAttribute('aria-controls')).classList.remove('is-hidden')
    e.target.focus()
    gsap.to(window, {
      duration: 1,
      scrollTo: { y: '#' + e.target.getAttribute('aria-controls'), offsetY: 100 },
    })
    if (e.target.getAttribute('aria-controls') === 'nasza-odpowiedzialnosc-tab') {
      this.runSliderElement()
    }
  }

  deactivateTabs = () => {
    this.tabs.forEach(tab => {
      tab.setAttribute('tabindex', '-1')
      tab.setAttribute('aria-selected', 'false')
    })
    this.panels.forEach(panel => {
      panel.classList.add('is-hidden')
    })
  }

  scrollTo = () => {
    gsap.to(window, { duration: 1, scrollTo: { y: '#tabs', offsetY: 100 } })
  }
  addScrollToListener = () => {
    document.querySelector('#sroll-top-tabs').addEventListener('click', this.scrollTo)
  }
}

window.addEventListener('DOMContentLoaded', () => {
  const tabs = document.querySelectorAll('.tabs')
  if (!tabs) return
  tabs.forEach(tab => {
    const tabs = new Tabs(tabs)
    tabs.init()
  })
})

export default Tabs
