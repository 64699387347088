import Vue from 'vue'
import EventBus from '../eventBus'
import axios from 'axios'

Vue.component('vue-job-offer-hero', {
  template: '#vue-job-offer-hero-template',
  props: {
    isAbroad: Boolean,
    openForDisability: Boolean,
    openForForeigners: Boolean,
    isRemote: Boolean,
    isJobimpulse: Boolean,
    job: Object,
  },
  data() {
    return {
      addTimeString: '',
    }
  },
  methods: {
    setaddTimeString(data) {
      const today = Date.now()
      const datum = Date.parse(data)
      const passedTime = (today - datum) / (60 * 60 * 24 * 1000)
      if (passedTime < 1) {
        this.addTimeString = 'Dzisiaj'
      } else if (passedTime < 2) {
        this.addTimeString = '1 dzień temu'
      } else {
        this.addTimeString = `${passedTime.toFixed()} dni temu`
      }
    },
  },
  created() {
    this.setaddTimeString(this.job.createdAt)
  },
})
