import axios from 'axios'
import EventBus from '../eventBus'
class SearchPostsController {
  constructor() {
    this.action = document.querySelector('.js-data').dataset.action
    this.filterButton = document.querySelector('.submit-button')
    this.form = document.querySelector('#posts-search-form')
    this.categorySelect = document.querySelector('#category')
    this.categoriesExcluded = document.querySelector('.js-data').dataset.excludedCat
      ? document.querySelector('.js-data').dataset.excludedCat
      : []
    this.currentPage = 1
    this.input = document.querySelector('#title')
    this.isDisabledNext = false
    this.isDisabledPrev = true
    this.isPagintationDisabled = false
    this.maxPages = document.querySelector('.js-data').dataset.max
    this.numberSelect = document.querySelector('#postsPerPage')
    this.pageID = document.querySelector('body').dataset.id
    this.pagination = document.querySelector('.pagination')
    this.postsWrapper = document.querySelector('.posts-wrapper')
    this.postsPerPage = document.querySelector('.js-data').dataset.postsPerPage
    this.timeout = null
    this.totalPosts = document.querySelector('.js-data').dataset.posts
    this.totalPostsEl = document.querySelector('.title-box-wrapper h2 .count')
    this.type = ''
    this.queryEl = document.querySelector('.title-box-wrapper h2 .query')
  }

  addFilterButtonListener = () => {
    this.filterButton.addEventListener('click', this.handleFormClick)
  }

  emitPosts = () => {
    EventBus.$emit('posts', {
      totalPages: this.maxPages,
      totalPosts: this.totalPosts,
      currentPage: parseInt(this.currentPage),
      isDisabledNext: this.isDisabledNext,
      isDisabledPrev: this.isDisabledPrev,
      isDisabled: this.isPagintationDisabled,
    })
  }

  getPosts = params => {
    axios
      .post('https://jobimpulse.pl/wp-admin/admin-ajax.php', params)
      // .post('https://localhost/jobimpulse/wp-admin/admin-ajax.php', params)
      //TODO no category id hardcoded
      .then(res => {
        this.postsWrapper.innerHTML = res.data.data[0].posts
        this.currentPage = res.data.data[0].currentPage
        this.maxPages = res.data.data[0].totalPages
        this.totalPosts = res.data.data[0].totalPosts
        if (this.totalPostsEl) this.viewTotalPosts(this.totalPostsEl)
        if (this.queryEl) this.viewQuery(this.queryEl)
        this.emitPosts()
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  handleFormClick = e => {
    e.preventDefault()
    this.setIsDisabledNext()
    this.setIsDisabledPrev()
    this.setIsPaginationDisabled()
    const url = new URL(window.location)
    url.searchParams.set('title', this.input.value)
    if (this.categorySelect) url.searchParams.set('category', this.categorySelect.value)
    window.history.pushState({}, '', url)
    if (this.numberSelect) this.totalPosts = this.numberSelect.value
    this.getPosts(this.setParams())
  }

  resetForm = () => {
    if (this.input) this.input.value = ''
    if (this.categorySelect) this.categorySelect.value = ''
    if (this.numberSelect) this.numberSelect.value = ''
  }

  setIsDisabledNext = () => {
    this.isDisabledNext = this.currentPage == this.maxPages || this.maxPages === 0 ? true : false
  }

  setIsDisabledPrev = () => {
    this.isDisabledPrev = this.currentPage == 1 && this.maxPages != 0 ? true : false
  }

  setInitialInputValue = () => {
    const searchParams = new URLSearchParams(window.location.search)
    if (this.input) this.input.value = searchParams.get('title') ? searchParams.get('title') : ''
    if (this.numberSelect) this.numberSelect.value = this.postsPerPage
    const url = new URL(window.location)
    url.searchParams.set('title', this.input.value)
    if (this.categorySelect) url.searchParams.set('category', this.categorySelect.value)
    window.history.pushState({}, '', url)
    if (this.numberSelect) this.totalPosts = this.numberSelect.value
    this.getPosts(this.setParams())
  }

  setParams = () => {
    const params = new URLSearchParams()
    params.append('action', this.action)
    params.append('current_page', this.currentPage)
    params.append('posts_per_page', this.numberSelect ? this.numberSelect.value : this.postsPerPage)
    params.append('tag', this.input ? this.input.value : '')
    params.append('max_pages', this.maxPages)
    params.append('category', this.categorySelect ? this.categorySelect.value : 39)
    params.append('title', this.input ? this.input.value : '')
    params.append('page_id', this.pageID)
    params.append('excluded_cats', this.categoriesExcluded ? this.categoriesExcluded : [])
    return params
  }

  viewTotalPosts = element => {
    element.innerHTML = this.totalPosts
  }

  viewQuery = element => {
    element.innerHTML = this.input.value
  }

  setIsPaginationDisabled = () => {
    this.isPagintationDisabled = !this.totalPosts || this.maxPages == 1 ? true : false
  }

  init = () => {
    this.setIsDisabledNext()
    this.setIsDisabledPrev()
    if (this.form) {
      this.resetForm()
      this.setInitialInputValue()
      this.addFilterButtonListener()
    }

    EventBus.$on('get-page', object => {
      this.currentPage = object.pageNumber
      this.setIsDisabledNext()
      this.setIsDisabledPrev()
      this.setParams()
      this.getPosts(this.setParams())
    })
  }
}

window.addEventListener('DOMContentLoaded', () => {
  const postsList = document.querySelector('.posts-list')
  if (!postsList) return
  const searchPosts = new SearchPostsController()
  searchPosts.init()
})
